import { Language } from '@lightningjs/sdk'

import OnboardingActivation from './OnboardingActivation'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { LoadingStateFactory } from './states/LoadingStateFactory'
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory'

import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import { LANGUAGES } from '../../../constants'

export default class ContentGatingActivation extends OnboardingActivation {
  override _titleTextKey = 'content_gating_activation_title'
  override _routerBackDisabled = false

  override _getActivationUrl() {
    return new URL(
      AppConfigFactorySingleton.config?.activation_url[Language.get() || LANGUAGES.DEFAULT]
    ).host
  }

  static override _states() {
    return [
      LoadingStateFactory(this),
      NewCodeStateFacrory(this),
      class Cancel extends this {
        override _getFocused() {
          return this.tag('CancelButton')
        }
        override _handleEnter(): void {
          sendMetric(EVENTS.CLICK, {
            name: 'Maybe Later',
          })
          super._handleBack()
        }
        override _handleUp() {
          this._setState('NewCode')
        }
      },
    ]
  }
}
