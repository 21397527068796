import { Language, Lightning, Log, Registry, Router, Utils } from '@lightningjs/sdk'
import QR from 'qrcode'

import BaseComponent from '../../../components/base'
import TextButton from '../../../components/buttons/TextButton'
import Loader from '../../../components/loader/Loader'

import { getIdentityRegCode, pairCredentialsWithBouncer } from '../../../api/Identity'
import AppConfigFactorySingleton from '../../../config/AppConfigFactory'
import {
  createFlexText,
  getBrandName,
  getPlatformName,
  PLATFORM_TYPES,
  PLATFORM_TYPES_ACTIVATION,
  templateDeepMerge,
} from '../../../helpers'
import {
  COLORS,
  FLEX_DIRECTION,
  FONT_FACE,
  LANGUAGES,
  PAGE_NAME,
  ROUTE,
  SCREEN_SIZE,
  TEXT_ALIGN,
  VERTICAL_ALIGN,
} from '../../../constants'
import { getActivationStrategy } from './strategies'
import { ACTIVATION_LANDING, ACTIVATION_REFERRER, ACTIVATION_TYPE } from './constants'
import { IActivation } from './IActivation'
import AuthenticationSingleton, {
  AuthenticationEvents,
} from '../../../authentication/Authentication'
import { ActivationStrategy } from './strategies/ActivationStrategy'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { EVENTS } from '../../../lib/analytics/types'
import { WithPeacockModal } from './hoc/WithPeacockModal'
import ModalManager, { CloseReason } from '../../../lib/ModalManager'
import { useRouterBackDisabled } from './hooks/useRouterBackDisabled'
import { Hook } from '../../../lib/Hook'
import { Subscription } from 'rxjs'

import TVPlatform from '../../../lib/tv-platform'
import { ErrorType } from '../../../lib/tv-platform/types'
import Announcer from '../../../lib/tts/Announcer'

export type ActivationModalData = {
  landing?: ACTIVATION_LANDING
  referrer?: ACTIVATION_REFERRER
  program?: any
  stream?: any
  id?: string
  videoId?: string
  closeCallback?: () => void
}

export class BaseActivation extends BaseComponent<any> implements IActivation {
  _timeout: ReturnType<typeof Registry.setTimeout>
  _strategy: ActivationStrategy = new ActivationStrategy(this)
  _landing = ACTIVATION_LANDING.MVPD
  _referrerType: ACTIVATION_REFERRER | undefined
  _program: any
  _stream: any
  _streamId = ''
  _routerBackDisabled = false
  _hook: Hook
  _titleTextKey = 'profile_link_msg'
  _hintLineHeight = 34
  _authnSubscription: Subscription
  _closeCallback?: () => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static override _template(override: any) {
    const brand = getBrandName()
    return templateDeepMerge(
      {
        rect: true,
        w: SCREEN_SIZE.width,
        h: SCREEN_SIZE.height,
        color: COLORS.dark,
        Background: {
          flexItem: false,
          rect: true,
          w: SCREEN_SIZE.width,
          h: SCREEN_SIZE.height,
          src: Utils.asset(`images/onboarding/bg-${brand}.png`),
        },
        ActivationScreen: {
          x: 200,
          y: 139,
          w: 1384,
          h: 941,
          Title: {
            text: {
              fontSize: 58,
              fontFace: FONT_FACE.light,
              textColor: COLORS.white,
              lineHeight: 74,
              verticalAlign: VERTICAL_ALIGN.middle,
              maxLines: 2,
              wordWrapWidth: 1384,
            },
          },
          Subtitle: {},
          Title1: {},
          Title2: {},
          Content: {
            y: 228,
            CodeContent: {
              Line1: {
                color: COLORS.white,
                flex: { direction: FLEX_DIRECTION.row },
              },
              Line2: {
                y: 65,
                text: {
                  fontSize: 34,
                  fontFace: FONT_FACE.light,
                  textColor: COLORS.white,
                  verticalAlign: VERTICAL_ALIGN.middle,
                  text: Language.translate('activation_line2'),
                },
              },
              CodeBackground: {
                y: 177,
                w: 670,
                h: 130,
                rect: true,
                color: COLORS.thunder1,
                Code: {
                  w: 670,
                  text: {
                    fontSize: 100,
                    fontFace: FONT_FACE.light,
                    textColor: COLORS.white,
                    verticalAlign: 'top',
                    textAlign: TEXT_ALIGN.center,
                  },
                },
                CodeLoader: {
                  visible: true,
                  alpha: 10,
                  type: Loader,
                  x: 180,
                  y: 30,
                },
              },
              GenerateButton: {
                y: 427,
                w: 420,
                h: 58,
                type: TextButton,
                radius: 29,
                fontSize: 30,
                fontFace: FONT_FACE.regular,
                focusFontColor: COLORS.dark,
                unfocusFontColor: COLORS.dark,
                focusBackGroundColor: COLORS.lightGray3,
                unfocusBackgroundColor: COLORS.lightGray3,
                label: Language.translate('gen_new_code').toUpperCase(),
                autoWidth: false,
                padding: 0,
              },
            },
            DividerContent: {
              x: 754,
              w: 150,
              h: 556,
              Divider1: {
                x: (w: any) => w / 2,
                mountX: 0.5,
                w: 2,
                h: 228,
                rect: true,
                color: COLORS.dark6,
              },
              Or: {
                y: 218,
                w: 150,
                text: {
                  fontSize: 46,
                  fontFace: FONT_FACE.light,
                  lineHeight: 100,
                  textColor: COLORS.white,
                  verticalAlign: VERTICAL_ALIGN.middle,
                  textAlign: TEXT_ALIGN.center,
                  text: Language.translate('OR'),
                },
              },
              Divider2: {
                x: (w: any) => w / 2,
                y: 328,
                mountX: 0.5,
                w: 2,
                h: 228,
                rect: true,
                color: COLORS.dark6,
              },
            },
            QRContent: {
              x: 921,
              QRText: {
                text: {
                  fontSize: 34,
                  fontFace: FONT_FACE.regular,
                  textColor: COLORS.white,
                  verticalAlign: VERTICAL_ALIGN.middle,
                  maxLines: 1,
                  textAlign: TEXT_ALIGN.center,
                  text: Language.translate('scan_qr'),
                },
              },
              QRPlaceHolder: {
                y: 86,
                texture: Lightning.Tools.getRoundRect(400, 400, 0, 1, COLORS.dark6, false, 0),
              },
              QRCode: {
                y: 86,
                w: 400,
                h: 400,
              },
              UsePhone: {
                y: 527,
                text: {
                  fontSize: 30,
                  fontFace: FONT_FACE.light,
                  lineHeight: 45,
                  textColor: COLORS.white,
                  verticalAlign: VERTICAL_ALIGN.middle,
                  maxLines: 1,
                  textAlign: TEXT_ALIGN.center,
                  text: Language.translate('use_phone_to_scan'),
                },
              },
            },
          },
        },
      },
      override
    )
  }

  set modalData(params: ActivationModalData) {
    const { id, videoId, program, stream, referrer, landing } = params
    this._hook = useRouterBackDisabled((value) => (this._routerBackDisabled = value))
    if (landing) this.landing = landing
    this._referrerType =
      landing === ACTIVATION_LANDING.MVPD && videoId
        ? ACTIVATION_REFERRER.VOD
        : (this._referrerType = referrer)
    this._program = program
    this._stream = stream
    const streamId: string | undefined = id || videoId || program?.mpxGuid
    if (streamId) this._streamId = streamId
    if (landing) {
      const titleTextKeys: any = {
        [ACTIVATION_LANDING.NBC]: 'profile_link_msg',
        [ACTIVATION_LANDING.MVPD]: 'live_stream_link_msg',
      }
      const titleTextKey = titleTextKeys[landing]
      if (titleTextKey) {
        this._titleTextKey = titleTextKey
        this.tag('Title').text.text = Language.translate(titleTextKey)
      }
    }
    this._closeCallback = params.closeCallback
    this._start()
  }

  set landing(landing: ACTIVATION_LANDING) {
    this._landing = landing
  }

  get titleTextKey() {
    return this._titleTextKey
  }

  override _setup() {
    super._setup()
    this._setState('Loading')
  }

  override _enable() {
    super._enable()
    this._authnSubscription = AuthenticationEvents.subscribe((type: ACTIVATION_TYPE) => {
      if (type === ACTIVATION_TYPE.MVPD) this._tvProviderLinked()
      else this._userProfileLinked()
    })
  }

  override _disable(): void {
    const listener = {
      [ACTIVATION_LANDING.MVPD]: ACTIVATION_TYPE.MVPD,
      [ACTIVATION_LANDING.NBC]: ACTIVATION_TYPE.NBC,
      [ACTIVATION_LANDING.FORK]: undefined,
    }[this._landing || ACTIVATION_LANDING.FORK]
    AuthenticationEvents.stopPolling(listener) // Just stop listening for events we were listening in first place
    this._hook?.unsubscribe()
    this._strategy?.destroy()
    this._authnSubscription.unsubscribe()
  }

  protected _getActivationTexts(): string[] {
    return [
      Language.translate('activation_line1'),
      this._getActivationUrl(),
      this.tag('Line2')?.text?.text,
    ].filter((text) => !!text)
  }

  _drawText() {
    this.tag('Line1').children = createFlexText(
      [
        Language.translate('activation_line1'),
        {
          text: this._getActivationUrl(),
          fontFace: FONT_FACE.semiBold,
        },
      ],
      {
        fontSize: this._hintLineHeight,
        verticalAlign: VERTICAL_ALIGN.middle,
      }
    )
  }

  _start() {
    this.generateCode()
    this._strategy = getActivationStrategy(this, this._referrerType)
    this._drawText()
    Announcer.announce([...this._strategy.drawText(), ...this._getActivationTexts()], {
      notification: true,
    })
  }

  override _inactive() {
    this._clearTimeout()
    this._setCode('')
    this._setState('Loading')
    Announcer.stop()
  }

  _clearTimeout() {
    if (this._timeout) Registry.clearTimeout(this._timeout)
  }

  _setCode(code: string) {
    this.tag('CodeLoader').visible = false
    const qrURL = this._getActivationUrl().includes('?')
      ? `${this._getActivationUrl()}&code=${code}`
      : `${this._getActivationUrl()}?code=${code}`
    this.tag('Code').text.text = code.toUpperCase().split('').join(' ')
    QR.toDataURL(qrURL)
      .then((QRCodeURL: any) => {
        this.tag('QRCode').src = QRCodeURL
      })
      .catch((error) => {
        Log.error('qrcode error: ', error)
      })
    if (code.length) {
      const focusedItem = this._getFocused()
      Announcer.announce(
        [
          code
            .split('')
            .map((text) => text + '\n')
            .join(''),
          focusedItem && 'announce' in focusedItem && typeof focusedItem.announce === 'string'
            ? focusedItem.announce
            : '',
        ],
        {
          append: true,
        }
      )
    }
  }

  _userProfileLinked() {
    // If we have the user cached in activate endpoint user profile will happen first
    if (this._landing !== ACTIVATION_LANDING.MVPD) this._handleLinkSuccess(ACTIVATION_TYPE.NBC)
  }

  _tvProviderLinked() {
    this._handleLinkSuccess(ACTIVATION_TYPE.MVPD)
  }

  _handleLinkSuccess(type: ACTIVATION_TYPE) {
    AuthenticationEvents.stopPolling(type)
    this._strategy?.success(type)
  }

  _onCodeSuccess(expires: number) {
    this._setState('NewCode')
    this._timeout = Registry.setTimeout(
      () => {
        this._clearTimeout()
        // on code timeout, generate a new code automatically
        this.generateCode()
      },
      (expires || 300) * 1000
    )
  }

  async generateCode() {
    if (AuthenticationSingleton.isMvpdTempPass()) await AuthenticationSingleton.logout()
    const promises = [getIdentityRegCode()]
    if (!AuthenticationSingleton.isAuthenticated())
      promises.push(AuthenticationSingleton.getRegCode())

    try {
      this.tag('Code').text.text = ''
      this.tag('CodeLoader').visible = true
      const [{ user_code, device_code, expires_in }, adobeCodeRes] = await Promise.all(promises)
      const internalPlatformName = getPlatformName()
      const platformName =
        internalPlatformName === PLATFORM_TYPES.XCLASS
          ? PLATFORM_TYPES.VIZIO
          : PLATFORM_TYPES_ACTIVATION[
              internalPlatformName.toUpperCase() as keyof typeof PLATFORM_TYPES_ACTIVATION
            ]
      await pairCredentialsWithBouncer({
        acode: adobeCodeRes?.code || null,
        idmcode: user_code,
        landing: this._landing,
        mvpd: AuthenticationSingleton.getMvpdData()?.mvpdDisplayName || null,
        platform: platformName,
      })

      this._clearTimeout()
      this._setCode(user_code)
      this._onCodeSuccess(expires_in)

      const pollPromises = []

      if (this._landing !== ACTIVATION_LANDING.NBC)
        pollPromises.push(AuthenticationEvents.pollAdobe())
      if (this._landing === ACTIVATION_LANDING.NBC || this._landing === ACTIVATION_LANDING.FORK)
        pollPromises.push(AuthenticationEvents.pollIDM(device_code))

      await Promise.all(pollPromises)
    } catch (error) {
      TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'An error occurred during code generation',
        payload: error,
      })
      Router.back()
    }
  }

  override _handleBack(e?: KeyboardEvent) {
    e?.preventDefault()
    e?.stopImmediatePropagation()
    this._strategy?.destroy()
    ModalManager.close(CloseReason.CANCELLED)
    AuthenticationEvents.stopPolling()
    const route = Router.getActiveHash()
    if (this._closeCallback) {
      this._closeCallback()
      return
    }
    if (route === 'live' || route === 'video/:videoId') {
      const page = Router.getActivePage() as any
      if (page && !this._routerBackDisabled) page.cancelledActivation = true
    } else if (route && route.includes(PAGE_NAME.replays.toLowerCase())) {
      sendMetric(EVENTS.MVPD_PAGE_ABANDONED, {
        registrationReferrer: 'Live Stream',
      })
      history.go(-1)
    } else {
      let registrationReferrer = 'MVPD Login'
      if (this._landing === ACTIVATION_LANDING.NBC) registrationReferrer = 'NBC Login'
      if (this._referrerType) {
        if (
          this._referrerType === ACTIVATION_REFERRER.LINEAR ||
          this._referrerType === ACTIVATION_REFERRER.EVENT
        )
          registrationReferrer = 'Live Stream'
        else if (this._referrerType === ACTIVATION_REFERRER.TEMPPASS)
          registrationReferrer = 'TempPass'
        else registrationReferrer = 'VOD Asset'
      }
      sendMetric(EVENTS.MVPD_PAGE_ABANDONED, {
        registrationReferrer,
      })
    }
    if (!this._routerBackDisabled) history.go(-1)
  }

  override _handleKey(e: KeyboardEvent): boolean {
    return true
  }

  _getBackPath() {
    return this._streamId || this._referrerType === ACTIVATION_REFERRER.LINEAR
      ? Router.getHistory()?.pop()?.hash
      : ROUTE.profile
  }

  static override _states() {
    return [
      class Loading extends this {
        override _getFocused() {
          return this
        }
      },
      class NewCode extends this {
        override _getFocused() {
          return this.tag('GenerateButton')
        }
        override _handleEnter() {
          Announcer.announce(this._getActivationTexts(), { notification: true })
          this.generateCode()
          return true
        }
      },
    ]
  }

  _getActivationUrl() {
    const language = Language.get() || LANGUAGES.DEFAULT
    return AppConfigFactorySingleton.config?.activation_url[language]
  }

  override _setTranslation = () => {
    this.tag('Content.Line2')?.patch({
      text: { text: Language.translate('activation_line2') },
    })
    this.tag('Content.GenerateButton')?.patch({
      label: Language.translate('gen_new_code').toUpperCase(),
      announce: Language.translate('gen_new_code_tts', Language.translate('gen_new_code')),
    })
  }
}

export default class extends WithPeacockModal(BaseActivation) {}
